









































import Vue from "vue";
interface Params {
  menu: any;
}
export default Vue.extend({
  data(): Params {
    return {
      menu:[
        {
          title:"基本情報",
          icon:"mdi-cube-outline",
          menu:[
            {title: "駐車場情報", path: "/WebUI/parkings", disabled: false },
          ]
        },
        {
          title:"入出庫検索",
          icon:"mdi-cube-outline",
          menu:[
            {title: "車両情報", path: "/WebUI/all-vehicles", disabled: false},
            {title: "未払い調査", path: "/WebUI/unpaid-researchs", disabled: false},
          ]
        },
        {
          title:"特定車両",
          icon:"mdi-cube-outline",
          menu:[
            {title: "特定車両利用履歴検索", path: "/WebUI/vehicle-informations", disabled: false},
          ]
        },
        {
          title:"料金",
          icon:"mdi-cube-outline",
          menu:[
            {title: "料金基本設定", path: "/WebUI/check-bases", disabled: false},
            {title: "カレンダー設定（曜日、期間）", path: "/WebUI/calendars", disabled: false},
            {title: "カレンダー設定（指定）", path: "/WebUI/calendar-holidays", disabled: false},
            {title: "金券設定", path: "/WebUI/cash_vouchers", disabled: false},
            {title: "サービス設定", path: "", disabled: true},
            {title: "QR/バーコード設定", path: "", disabled: true},
            {title: "プリペイドカード設定", path: "", disabled: true},
            {title: "券種設定", path: "", disabled: true},
            {title: "月極定期管理", path: "", disabled: true},
            {title: "料金テーブル設定", path: "", disabled: true},
          ]
        },
        {
          title:"精算機設定",
          icon:"mdi-cube-outline",
          menu:[
            {title: "アラート設定", path: "", disabled: true},
            {title: "精算機/ローカルサーバー設定", path: "", disabled: true},
            {title: "4桁プラス設定", path: "", disabled: true},
            {title: "精算内容訂正", path: "", disabled: true},
            {title: "利用証明書印字内容設定", path: "", disabled: true},
            {title: "預かり証印字設定", path: "", disabled: true},
          ]
        },
        {
          title:"精算機ログ",
          icon:"mdi-cube-outline",
          menu:[
            {title: "アラート・エラー一覧", path: "/WebUI/errors", disabled: false},
          ]
        },
        {
          title:"車両一覧",
          icon:"mdi-cube-outline",
          menu:[
            {title: "未払車両一覧", path: "", disabled: true },
            {title: "不正車両一覧", path: "", disabled: true},
            {title: "長期入庫車両一覧", path: "/WebUI/long-term-parking-vehicle", disabled: false},
            {title: "？あり車両一覧", path: "/WebUI/miss-recognized-vehicles", disabled: false},
          ]
        },
        {
          title:"車両管理",
          icon:"mdi-cube-outline",
          menu:[
            {title: "アラート通知車両管理", path: "", disabled: true},
            {title: "精算不要車両管理", path: "", disabled: true},
            {title: "月極車両管理", path: "", disabled: true},
            {title: "自動出庫除外車両", path: "", disabled: true},
          ]
        },
        {
          title:"集計を行う",
          icon:"mdi-cube-outline",
          menu:[
            {title: "売上管理", path: "", disabled: true},
            {title: "電子マネー", path: "", disabled: true},
            {title: "入出庫台数", path: "", disabled: true},
            {title: "未払い精算", path: "", disabled: true},
          ]
        },
        {
          title:"共有DB",
          icon:"mdi-cube-outline",
          menu:[
            {title: "車両メモ", path: "", disabled: true}
          ]
        },
        {
          title:"その他",
          icon:"mdi-cube-outline",
          menu:[
            {title: "ソフトウェアアップデート", path: "", disabled: true}
          ]
        },
      ]
    }
  },
})
