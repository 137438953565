





































































import Vue from "vue";
import axios from "axios";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"
import VehicleInformations from "@/components/WebUI/VehicleInformations/index.vue";

interface Params {
  headers: any[];
  selectObj: any[];
  dialog: boolean;
  show: any[];
  successCarNumber:string;
}
export default Vue.extend({

  components:{
    ObjectToCsv,
    ObjectToBacklog,
    VehicleInformations
  },

  data(): Params {
    return {
      successCarNumber:"",
      show: [],
      dialog: false,
      headers: [
                { text: '物件名', value: "parkingName" },
                { text: 'id', value: "parkingId" },
                { text: '入庫日時', value: "entrantTime" },
                { text: '出庫日時', value: "exitTime" },
                { text: 'ナンバー', value: "fullNumber" },
                { text: '履歴', value: "history" },
                { text: '状態', value: "vehicleStatusId" },
                { text: '軽自動車フラグ', value: "keiCarFlg" },
                { text: 'ナンバー削除', value: "logStatusFixFlg" },
                { text: '削除日時', value: "logCreateDte" },
                { text: '削除理由', value: "logKindId" },
                { text: '車両ではない', value: "notNumberFlg" },
                { text: '手入力', value: "manWorkFlg" },
                { text: '再課金', value: "reaccountFlg" },
                { text: '不正出庫', value: "unpayExitFlg" },
                { text: '利用時間', value: "parkingTime" },
                { text: '請求金額', value: "accountTotal" },
                { text: '現金支払', value: "payCash" },
                { text: '電子マネー', value: "payIcCard" },
                { text: 'クレジット', value: "payCredit" },
                { text: 'QR決済', value: "payQr" },
                { text: '最大料金', value: "payMaxFlg" },
                { text: 'サービス超過', value: "serviceExcessFlg" },
                { text: '精算操作有無', value: "payOperationFlg" },
                { text: '支払情報', value: "payMethodsFlg" },
                { text: '変更日', value: "sheets" },
                { text: 'サービス時間内未精算出庫', value: "unpayServiceExitFlg" },
                { text: 'ブラック', value: "blackFlg" },
                { text: 'ホワイト', value: "whiteFlg" },
                { text: '月極車両', value: "monthlyNumberFlg" },
                { text: 'ダミー車両', value: "dummyFig" },
                { text: '画像', value: "image" },
                { text: '入庫間口', value: "entranceName" },
                { text: '出庫間口', value: "exitName" },
      ],
      selectObj: [],
    }
  },

  methods: {
    async history(place:string, classNumber:string, kana:string, carNumber:string): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_show`;
      const formData = { params: {
                                  server:      this.selectServer,
                                  place:       place,
                                  classNumber: classNumber,
                                  kana:        kana,
                                  carNumber:   carNumber
                        } };
      return await axios
      .get(url, formData)
      .then(res => {
        this.successCarNumber = place + ' ' + classNumber + ' ' + kana + ' ' + carNumber;
        this.show = res.data;
        this.dialog = true;
      })
      .catch(error => {
        console.log(error);
        alert("履歴の取得に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide()
      });
    },
  },
  props:{
    result:{},
    selectServer:{}
  }
});
