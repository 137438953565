














































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
import VehicleInformations from "@/components/WebUI/VehicleInformations/index.vue";
interface Data {
  searchType:boolean;
  date:string;
  number:string;
  result:[];
  successCarNumber:string;
  show:[];
  dialog:boolean;
  headers:any[];
  valid:boolean;
}
export default Vue.extend({
  data(): Data {
    return {
      searchType: false,
      date:"",
      number:"",
      result:[],
      successCarNumber:"",
      show:[],
      dialog:false,
      headers:[
        { text: 'pid', value: "PARKING_ID" },
        { text: '入庫日時', value: "ENTRANT_TIME" },
        { text: '出庫日時', value: "EXIT_TIME" },
        { text: '車両ナンバー', value: "number" },
        { text: '履歴', value: "history" },
        { text: '請求金額', value: "PAY_ARREARS" },
        { text: '請求フラグ', value: "PAY_REQ_FLG" },
        { text: '支払いフラグ', value: "PAY_FINISHED_FLG" },
        { text: '画像', value: "image" },
      ],
      valid:false
    }
  },
  components:{
    VehicleInformations
  },
  methods: {
    reset() {
      this.date = "";
      this.number = "";
    },
    async history(place:string, classNumber:string, kana:string, carNumber:string): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_show`;
      const formData = { params: {
                                  server:      this.selectServer,
                                  place:       place,
                                  classNumber: classNumber,
                                  kana:        kana,
                                  carNumber:   carNumber
                        } };
      return await axios
      .get(url, formData)
      .then(res => {
        this.successCarNumber = place + ' ' + classNumber + ' ' + kana + ' ' + carNumber;
        this.show = res.data;
        this.dialog = true;
      })
      .catch(error => {
        console.log(error);
        alert("履歴の取得に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide()
      });
    },
    async send(searchType:boolean, date:string, number:string): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/in_out_mgr/search`;
      const formData = { params: {
                                  id:     this.selectServer,
                                  searchType: searchType,
                                  date:       date,
                                  number:     number,
                        } };
      return await axios
      .get(url, formData)
      .then(res => {
        this.result = res.data;
      })
      .catch(error => {
        console.log(error);
        alert("検索に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  },
});
